export const SEX_CHOICES = [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
    { value: 'T', label: 'Transgendered' },
    { value: 'O', label: 'Other' },
    { value: 'U', label: 'Undefined' },
  ];
  
  export const PROVINCE_CHOICES = [
    { value: 'AB', label: 'Alberta' },
    { value: 'BC', label: 'British Columbia' },
    { value: 'MB', label: 'Manitoba' },
    { value: 'NB', label: 'New Brunswick' },
    { value: 'NL', label: 'Newfoundland and Labrador' },
    { value: 'NS', label: 'Nova Scotia' },
    { value: 'NT', label: 'Northwest Territories' },
    { value: 'NU', label: 'Nunavut' },
    { value: 'ON', label: 'Ontario' },
    { value: 'PE', label: 'Prince Edward Island' },
    { value: 'QC', label: 'Quebec' },
    { value: 'SK', label: 'Saskatchewan' },
    { value: 'YT', label: 'Yukon' },
  ];
  
  export const AGREED_CHOICES = [
    { value: 1, label: 'Yes' },
    { value: 0, label: 'No' },
  ];
  

export const STATUS = [
  { value : 1, label:'Active'},
  { value : 0, label:'Inactive'}
];
      
export const STATUS_SIGN = [
  { value : 1, label:'Active'},
  { value : 0, label:'✗'}
];
      

export const DOCTOR_TYPE = [
  {value: 'Family Doctor',label: 'Family Doctor'},
  {value: 'Walk In', label: 'Walk In'}
];


export const APPOINTMENTTYPE = [
  {value:0 , label:'Clinic'},
  {value:1 , label:'Phone'},
  {value:2 , label:'Video'},
]