import React, {useState, useEffect, useRef} from 'react';
import API_BASE_PATH from './apiConfig';
import {
    Button,
    Grid,
    Typography,
    AppBar,
    Toolbar,
    Divider,
    Container,
    CssBaseline,
    Paper,
} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {Helmet} from 'react-helmet';
import ndHealthLogo from './nd-health-logo.png';
import Footer from './components/landing_contents/footer';
import About from './components/landing_contents/about_us_test';
import CustomAppBar from './components/landing_contents/Appbar';
import Features from './components/landing_contents/Features';
import Testimonials from './components/landing_contents/testimonials';
import FAQ from './components/landing_contents/FAQ';
import EduVideos from './components/landing_contents/EduVideos';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StyledTitle from './components/landing_contents/components/StyledTitle';
import {SignupForm} from './components/landing_contents/Signup';
import {useParams, useNavigate} from "react-router-dom";
import {Login} from './components/resources/Login';
import axios from "axios";
import {makeStyles} from "@mui/styles";
import {brown} from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backdropFilter: 'blur(10px)', // Creates the blur effect
        backgroundColor: 'rgba(232,215,215,0.3)', // Semi-transparent white
        transition: 'all 0.3s ease-in-out',
        borderRadius: '5px',
        // border: '2px solid transparent',
        position: 'sticky',
        left: '50%', // Center horizontally

    },
    stickyAppBar: {
        position: 'sticky',
        top: 0,
        zIndex: 1100, // Ensure it's above other content

    },
    toolbar: {
        borderRadius: '5rem', zIndex: 1000, border: '1px', position: 'sticky',

    },
    logo: {
        width: '80px',
        height: '80px',
        borderRadius: '50%',
    },
}));


const lightTheme = createTheme({
    palette: {
        mode: 'light', // Ensure the theme is in light mode
        primary: {
            main: 'rgba(212,216,230,0.2)', //'#ffffff', // Creamy color for primary elements
        },
        background: {
            default: '#ebedf5', // Creamy color for primary elements
        },
    },
});

const darkTheme = createTheme({palette: {mode: 'dark'}});

const Home = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);

    const placeholders = ['Clinic near Scarborough', 'walkin clinic', 'Flue shots'];
    const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [blueTheam, setBlue] = useState("#1975D1");
    const [whiteTheam, setWhiteTheam] = useState("#ffffff");
    const demoRequestFormRef = useRef(null);
    const featuresRef = useRef(null); // Create a ref for the Features section
    const navigate = useNavigate();
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const [check_Login, setCheck_Login] = useState(false);
    const accessToken = localStorage.getItem('accessToken');
    const classes = useStyles();

    const handleOpenLogin = () => {
        setIsLoginOpen(true);
    };

    const handleCloseLogin = () => {
        setIsLoginOpen(false);
    };

    const handleOpenSignup = () => {
        setIsSignupOpen(true);
    };
    const handleCloseSignUp = () => {
        setIsSignupOpen(false);
    };

    // Function to scroll to the DemoRequestForm section
    const scrollToDemoRequestForm = () => {
        demoRequestFormRef.current.scrollIntoView({behavior: 'smooth'});
    };

    // Function to scroll to the Features section
    const scrollToFeatures = () => {
        featuresRef.current.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 2000); // Change the interval duration (in milliseconds) as needed

        const head = document.querySelector('head');
        const calendlyScript = document.createElement('script');
        calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(calendlyScript);

        return () => {
            clearInterval(interval);
            head.removeChild(calendlyScript);
        };
    }, []);

    const handleSearh = async () => {
        try {
            const response = await fetch(`${API_BASE_PATH}/search/?query=$${searchQuery}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const contentType = response.headers.get('content-type');
            if (!contentType || !contentType.includes('application/json')) {
                throw new Error('Response is not in JSON format');
            }
            const data = await response.json();
            setSearchResults(data.results);
        } catch (error) {
            console.error('Error searching clinics:', error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearh();
        }
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
        setIsDrawerOpen(true);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
        setIsDrawerOpen(false);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const goToSignup = () => {
        navigate(`/signup`);
    }

    return (
        <div>


            <Paper
                sx={{
                    paddingTop: '1rem',
                    background: 'linear-gradient(45deg, #ffff 30%, #ffff 90%)',
                }}
            >
                {/*<Container component="main" maxWidth="xl" sx={{mt: 2, paddingBottom: '1rem', paddingTop: '1rem'}}>*/}
                    {/*<CustomAppBar*/}
                    {/*    scrollToFeatures={scrollToFeatures}*/}
                    {/*    handleOpenLogin={handleOpenLogin}*/}
                    {/*    handleOpenSignup={handleOpenSignup}*/}
                    {/*    handleOpenNavMenu={handleOpenNavMenu}*/}
                    {/*    handleCloseNavMenu={handleCloseNavMenu}*/}
                    {/*/>*/}

                    <ThemeProvider theme={lightTheme}>
                        <AppBar position="sticky" className={`${classes.appBar} ${classes.stickyAppBar}`}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters className={classes.toolbar}>
                                    <img
                                        src={ndHealthLogo}
                                        alt="ND Health, book online family and walkin appointment"
                                        style={{width: '80px', height: '80px', borderRadius: '50%'}}
                                    />
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="a"
                                        href="#app-bar-with-responsive-menu"
                                        sx={{
                                            mr: 2,
                                            paddingRight: '1rem',
                                            paddingLeft: '1rem',
                                            display: {xs: 'none', md: 'flex'},
                                            fontWeight: 700,
                                            letterSpacing: '.1rem',
                                            color: '#1975D1',
                                            textDecoration: 'none',
                                        }}
                                    >
                                        ND Health
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        noWrap
                                        component="a"
                                        href="#app-bar-with-responsive-menu"
                                        sx={{
                                            mr: 2,
                                            display: {xs: 'flex', md: 'none'},
                                            flexGrow: 1,
                                            fontFamily: 'monospace',
                                            fontWeight: 700,
                                            color: '#1975D1',
                                            textDecoration: 'none',
                                            paddingLeft: '0.1rem',
                                        }}
                                    >
                                        ND Health
                                    </Typography>

                                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                        <Button
                                            key="Features"
                                            onClick={scrollToFeatures} // Update the click handler
                                            sx={{
                                                my: 2,
                                                color: brown[500],
                                                display: 'block',
                                                fontFamily: 'monospace',
                                                fontWeight: 'bold',
                                                fontSize: '1rem'
                                            }}
                                        >
                                            Features
                                        </Button>
                                        <Button
                                            key="Login"
                                            onClick={handleOpenLogin}
                                            sx={{
                                                my: 2,
                                                color: brown[500],
                                                display: 'block',
                                                fontFamily: 'monospace',
                                                fontWeight: 'bold',
                                                fontSize: '1rem'
                                            }}
                                        >
                                            Login
                                        </Button>
                                        <Button
                                            key="Signup"
                                            onClick={handleOpenSignup}
                                            sx={{
                                                my: 2,
                                                color: brown[500],
                                                display: 'block',
                                                fontFamily: 'monospace',
                                                fontWeight: 'bold',
                                                fontSize: '1rem'
                                            }}
                                        >
                                            Signup
                                        </Button>

                                    </Box>

                                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            color="inherit"
                                        >
                                            <MenuIcon/>
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorElNav}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            open={Boolean(anchorElNav)}
                                            onClose={handleCloseNavMenu}
                                            sx={{
                                                display: {xs: 'block', md: 'none'},
                                            }}
                                        >
                                            <MenuItem onClick={scrollToFeatures}>
                                                <Typography sx={{
                                                    my: 2,
                                                    color: brown[500],
                                                    display: 'block',
                                                    fontFamily: 'monospace',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem'
                                                }}
                                                            textAlign="center">Features</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={goToSignup}>
                                                <Typography sx={{
                                                    my: 2,
                                                    color: brown[500],
                                                    display: 'block',
                                                    fontFamily: 'monospace',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem'
                                                }}
                                                            textAlign="center">Signup</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Toolbar>
                            </Container>
                        </AppBar>
                    </ThemeProvider>

                    <CssBaseline/>
                    <About/>

                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>

                    <div ref={featuresRef}>
                        <Features/>
                    </div>

                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>
                    <Testimonials/>

                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>
                    <FAQ/>

                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>

                    <EduVideos/>
                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>

                    <Grid spacing={2} style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                        <Grid>
                            <div style={{textAlign: 'center'}}>
                                <StyledTitle center>Experience the Difference: Book a Demo</StyledTitle>
                            </div>
                            <div className="calendly-inline-widget" data-url="https://calendly.com/nd-health"
                                 style={{minWidth: '320px', height: '646px'}}></div>
                        </Grid>
                    </Grid>
                    <Divider style={{paddingTop: '1rem', paddingBottom: '1rem'}}/>
                    <CssBaseline/>
                {/*</Container>*/}
            </Paper>

            {/* New SEO Section */}
            <Helmet>
                <title>ND Health - Streamlining Healthcare Operations</title>
                <meta name="description"
                      content="Discover how ND Health streamlines healthcare operations, saving time and improving patient care. Explore features such as efficient physician appointment management, seamless patient communication, and secure record sharing."/>
                <meta name="keywords"
                      content="family physician, family doctor, walk-in physician, book appointment, appointment management, patient communication, secure record sharing, healthcare services"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <link rel="canonical" href="https://nd-health.ca"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>

            <Footer/>
            {isLoginOpen && <Login open={isLoginOpen} onClose={handleCloseLogin}/>}
            {isSignupOpen && <SignupForm open={isSignupOpen} onClose={handleCloseSignUp}/>}
        </div>
    );
};

export default Home;
