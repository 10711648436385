import './App.css';
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';
import ClinicInfo from './components/clinicInfo';
import FamilyAppointmentPage from './components/FamilyAppointmentPage';
import WalkinAppointmentPage from './components/WalkinAppointmentPage';
import ClinicPolicy from './components/ClinicPolicy';
import ManageAppointment from './components/ManageAppointment';
import TerminalPage from './components/TerminalPage';
import ClinicDashboard from './components/clinic/ClinicDashboard';
import DemoRequestForm from './components/landing_contents/DemoRequestForm';
import RequestDemographic from './components/RequestDemographic';
import DynamicForm from './components/eforms/eformV2';
import EformOauth from './components/clinic_to_patient/EformOauth';
import RecordOauth from './components/clinic_to_patient/record';
import LinkedEformToPatient from './components/clinic_to_patient/LinkedEformToPatient';
import DynamicFormBuilder from './components/eforms/GenerateEform'
import DisplayPolicy from "./components/Policy/DisplayPolicy";
import {SignupForm} from "../src/components/landing_contents/Signup";
import {PasswordResetConfirm} from "./components/landing_contents/ResetPassword";
import ClinicLanding from "./components/clinic/home/ClinicLanding";
import PasswordReset from "./components/resources/ResetPassword";
import UpdateProfileOauth from "./components/clinic_to_patient/UpdateProfileOauth";



const App = () => {

    return (
        // <Layout clinicInfo={"clinicInfo"} >
        <Router>
            <Routes>
                <Route path="/clinic/:clinicSlug/appointment" element={<ClinicInfo/>}/>
                <Route path="/clinic/:clinicSlug/" element={<ClinicLanding/>}/>
                <Route path="/clinic/:clinicSlug/policy" element={<ClinicPolicy/>}/>
                <Route path="/clinic/:clinicSlug/manageappointment" element={<ManageAppointment/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/family-appointment/:clinicSlug" element={<FamilyAppointmentPage/>}/>
                <Route path="/walkin-appointment/:clinicSlug" element={<WalkinAppointmentPage/>}/>
                <Route path="/:clinicSlug/terminal/:clinicUid/:clinic" element={<TerminalPage/>}/>
                <Route path="/clinic/:clinicSlug/home/" element={<ClinicDashboard/>}/>
                {/* patient related */}
                <Route path='/demo' element={<DemoRequestForm/>}/>
                <Route path='/EformOauth/:clinicSlug/' element={<EformOauth/>}/>
                <Route path='/RecordOauth/:clinicSlug/' element={<RecordOauth/>}/>
                <Route path='/clinic-forms/:clinicSlug/' element={<LinkedEformToPatient/>}/>
                <Route path="/patient/:clinicSlug/requestpatientprofile" element={<RequestDemographic/>}/>
                <Route path='/patient/:clinicSlug/eform' element={<DynamicForm/>}/>
                <Route path='/clinic/:clinicSlug/createEform' element={<DynamicFormBuilder/>}/>
                <Route path='/clinic/:clinicSlug/UpdateProfileOauth' element={<UpdateProfileOauth/>}/>
                <Route path='/clinic/:clinicSlug/resetPassword' element={<PasswordReset/>}/>
                <Route path='OurPolicy' element={<DisplayPolicy/>}/>
                {/*<Route path='signup' element={<SignupForm/>}/>*/}
                <Route path='resetpassword/:uidb64/:token' element={<PasswordResetConfirm/>}/>
                {/*<Route path='/DynamicForm' element={<App10/>} />*/}
            </Routes>
        </Router>
        // </Layout>
    );
};

export default App;
